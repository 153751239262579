/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import styled from '@emotion/styled';

import useOutsideClick from '../../hooks/useOutsideClick';
import useCombinedRefs from '../../hooks/useCombineRefs';
import Icon, { IconName } from '../Icon';
import Input from '../Input';
import Checkbox from '../Checkbox';

export type DropdownOption<T = any> = {
  value: T;
  text: React.ReactNode;
  displayText?: string;
  disabled?: boolean;
  search?: any;
  label?: React.ReactNode;
  noSearch?: boolean;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The content of the label.
   */
  label?: React.ReactNode;
  /**
   * Error state. If used as string error text will appear below the input.
   */
  error?: boolean | React.ReactNode;
  /**
   * Success state. Makes icon green and adds default 'check-circle' icon.
   */
  success?: boolean;
  /**
   * Name of an icon to dispay on the right side of the input.
   */
  icon?: IconName;
  /**
   * An array of dropdown options.
   */
  options?: DropdownOption[];
  /**
   * Selected option value or an array of values if multiple is selected.
   */
  value?: any;
  /**
   * Makes dropdown searchable.
   */
  search?: boolean;
  /**
   * Allows multiple options selection.
   */
  multiple?: boolean;
  /**
   * Display "All" option (used with 'multiple').
   */
  optionAll?: boolean;
  /**
   * Custom option "All" label
   */
  optionAllLabel?: string;
  /**
   * Display selected options tags (used with 'multiple').
   */
  tags?: boolean;
  /**
   * Disables options selection.
   */
  disabled?: boolean;
  /**
   * Additional dropdown target props.
   */
  targetProps?: Record<string, any>;
  /**
   * Replaces input with button, disables search and other forms-related props.
   */
  buttonLike?: boolean;
  /**
   * Optional text if no option is selected.
   */
  placeholder?: string;
  /**
   * Same as placeholder, but higher priority.
   */
  text?: string;
  /*
   ** Attribute for auto tests
   */
  'data-cy'?: string;
  /**
   * Overrides default option onClick handler.
   */
  onChange?: (value) => void;
  optionsTop?: React.ReactNode;
  showClearButton?: boolean;
}

const Dropdown: React.FC<DropdownProps> = React.forwardRef(
  (
    {
      label,
      error,
      success,
      icon = 'chevron',
      options = [],
      value,
      search,
      showClearButton = true,
      multiple,
      optionAll,
      optionAllLabel,
      tags,
      disabled,
      buttonLike = false,
      placeholder,
      text = '',
      onChange,
      className,
      targetProps = {},
      optionsTop,
      'data-cy': dataCY,
      ...props
    },
    forwardRef
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const containerRef = useCombinedRefs<any>(ref, forwardRef);
    const optionsTargetRef = useRef<HTMLDivElement>(null);

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { attributes } = usePopper(optionsTargetRef.current, popperElement, {
      placement: 'bottom-start',
      modifiers: [
        { name: 'flip', options: { fallbackPlacements: ['top', 'right'] } },
        { name: 'offset', options: { offset: [0, 10] } }
      ]
    });

    const [open, setOpen] = useState(false);

    const [searchString, setSearchString] = useState('');
    const selectedOption = options.find(option => option.value === value);
    const selectedMultipleOptions =
      multiple && Array.isArray(value)
        ? options.filter(option => value.includes(option.value))
        : [];
    let selectedOptionText = '';
    if (selectedOption) {
      selectedOptionText = String(selectedOption.displayText || selectedOption.text);
    }

    const dropdownText = text || selectedOptionText;

    useEffect(() => {
      if (!open && search) setSearchString('');
    }, [open, search]);

    const filteredOptions = options.filter(
      option =>
        option.noSearch ||
        String(option.search || option.text)
          .toLowerCase()
          .includes(searchString.toLowerCase())
    );

    const toggleOpen = () => {
      if (!disabled) setOpen(value => !value);
    };

    const handleOptionClick = optionValue => {
      if (onChange) onChange(optionValue);
      if (!multiple) setOpen(false);
      if (searchString) setSearchString('');
    };

    const handleMultipleSelect = optionValue => {
      const newValues = value.includes(optionValue)
        ? value.filter(option => option !== optionValue)
        : value.concat(optionValue);
      if (onChange) {
        onChange(newValues);
      }
    };

    const toggleSelectAll = e => {
      const { checked } = e.target;
      if (!onChange) return;
      if (checked) onChange([]);
    };

    useOutsideClick(containerRef.current, () => {
      if (open) setOpen(false);
    });

    const showTags = multiple && tags && selectedMultipleOptions.length < options.length;

    if (success && !icon) icon = 'check';

    const dropdownOptions = open && (
      <StyledDropdownOptions
        className="dropdown-options scrollbar-custom"
        ref={setPopperElement as any}
        {...attributes.popper}>
        {optionsTop}
        {search && (
          <div className="search-dropdown-inner">
            <Input
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              icon="search"
              placeholder="Search"
              className="search-input"
              data-cy="input_search"
            />
            {showClearButton && (
              <button className="cancel-btn" onClick={() => setSearchString('')}>
                <Icon name="cancel" size={8} />
              </button>
            )}
          </div>
        )}
        <ul>
          {optionAll && (
            <StyledDropdownOptionCheckbox>
              <Checkbox
                label={optionAllLabel || 'All'}
                checked={value.length === 0 || value.length === options.length}
                onChange={toggleSelectAll}
                disabled={value.length === 0 || value.length === options.length}
                data-cy="checkbox_all"
              />
            </StyledDropdownOptionCheckbox>
          )}
          {filteredOptions.map(option =>
            multiple ? (
              <StyledDropdownOptionCheckbox key={option.value}>
                <Checkbox
                  label={option.label || option.text}
                  checked={value.includes(option.value)}
                  onChange={() => handleMultipleSelect(option.value)}
                  data-cy={`checkbox_${String(option.value).replace(/\s/g, '_')}`}
                />
              </StyledDropdownOptionCheckbox>
            ) : (
              <StyledDropdownOption
                key={option.value}
                onClick={e =>
                  option.onClick ? option.onClick(e) : handleOptionClick(option.value)
                }
                className={clsx('option', {
                  active: option.value === value,
                  disabled: option.disabled
                })}
                data-cy={`checkbox_${String(option.value).replace(/\s/g, '_')}`}>
                {option.value === value && <Icon name="checkmark" />}
                {option.label || option.text}
              </StyledDropdownOption>
            )
          )}
        </ul>
        {filteredOptions.length === 0 && <p className="no-results">No results</p>}
      </StyledDropdownOptions>
    );

    return (
      <StyledDropdown
        ref={containerRef}
        className={clsx(
          'dropdown',
          {
            open,
            label,
            icon,
            success,
            disabled,
            search,
            error: error && !open,
            'button-like': buttonLike,
            placeholder: !dropdownText
          },
          className
        )}
        data-cy={dataCY}
        {...props}>
        {buttonLike ? (
          <>
            <StyledDropdownButton
              className="dropdown-button"
              onClick={toggleOpen}
              type="button"
              data-cy={dataCY ? `${dataCY}_dropdown_button` : undefined}
              {...targetProps}>
              <label>{label}</label>
              <span className="dropdown-text">{dropdownText || placeholder}</span>
              <Icon name={icon} />
            </StyledDropdownButton>
            {dropdownOptions}
          </>
        ) : (
          <>
            <label>{label}</label>
            <div
              ref={optionsTargetRef}
              onClick={toggleOpen}
              className="dropdown-inner"
              data-cy={dataCY ? `${dataCY}_dropdown_inner` : undefined}>
              <div className="dropdown-text" {...targetProps}>
                {dropdownText || placeholder}
              </div>
              {!disabled && <Icon name={icon} />}
            </div>
            {dropdownOptions}
            {!open && <div className="error-text">{error}</div>}
          </>
        )}

        {showTags && (
          <div className="dropdown-tags">
            {selectedMultipleOptions.map(option => (
              <StyledOptionTag key={option.value}>
                {option.text}{' '}
                <button
                  className="option-remove-btn"
                  type="button"
                  onClick={() => handleMultipleSelect(option.value)}>
                  <Icon name="mini-x" size={8} />
                </button>
              </StyledOptionTag>
            ))}
          </div>
        )}
      </StyledDropdown>
    );
  }
);

export default React.memo(Dropdown);

const StyledDropdown = styled.div`
  position: relative;
  width: 300px;

  &.open {
    .dropdown-inner .icon-chevron {
      transform: rotate(180deg) translateY(50%);
    }
    .dropdown-button .icon-chevron {
      transform: rotate(180deg);
      fill: ${props => props.theme.colors.red};
    }
  }

  .no-results {
    padding: 16px 12px;
    justify-content: center;
    color: ${props => props.theme.colors.grayDark};
    pointer-events: none;
  }

  &.disabled {
    .dropdown-inner {
      pointer-events: none;
      background: ${props => props.theme.colors.lightGray};
      border-radius: ${props => props.theme.misc.borderRadius};
      .dropdown-text {
        background: ${props => props.theme.colors.lightGray};
        color: ${props => props.theme.colors.gray};
      }
    }
  }

  &.icon .dropdown-inner .dropdown-text {
    padding-right: 26px;
  }

  &.error .dropdown-inner {
    .dropdown-text {
      border-color: ${props => props.theme.colors.red};

      &:hover {
        border-color: ${props => props.theme.colors.errorRed};
      }
    }

    & > .icon {
      fill: ${props => props.theme.colors.red};
    }
  }

  &.search .dropdown-text {
    cursor: text;
  }

  &.button-like {
    width: auto;
    display: inline-block;
  }

  &.placeholder .dropdown-text {
    color: ${props => props.theme.colors.grayDark};
  }

  .dropdown-inner {
    position: relative;
    outline: none;

    &:focus .dropdown-text {
      border-color: black;
    }

    .dropdown-text {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid ${props => props.theme.colors.seashell};
      border-radius: ${props => props.theme.misc.borderRadius};
      padding: 0 8px;
      font-size: 12px;
      line-height: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      background: #ffffff;

      &:hover {
        border-color: black;
      }

      &:disabled {
        background: ${props => props.theme.colors.lightGray};
        color: ${props => props.theme.colors.gray};
        pointer-events: none;

        &::placeholder {
          color: ${props => props.theme.colors.gray};
        }
      }

      &::placeholder {
        color: ${props => props.theme.colors.grayDark};
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;

      &.icon-chevron {
        cursor: pointer;
      }
    }
  }

  .search-dropdown-inner {
    overflow: hidden;
    position: relative;
    margin: 12px 0 4px 0;

    .cancel-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 20px;
      border: none;
      background: none;
      padding: 0;
      height: 12px;
      width: 12px;
      outline: none;
      fill: #70727e;

      &:hover,
      &:active {
        opacity: 0.7;
      }
    }

    .search-input {
      padding: 0 12px;
      box-sizing: border-box;

      &.input {
        width: 100%;
      }

      .input-component {
        padding: 8px 20px 8px 29px;
        height: 32px;
      }

      .icon-search {
        left: 10px;
        fill: ${props => props.theme.colors.gray};
      }

      .clear {
        fill: ${props => props.theme.colors.red};
      }

      .error-text:empty {
        display: none;
      }
    }
  }

  .dropdown-tags {
    display: flex;
    flex-wrap: wrap;
  }

  & > label {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 4px;
    display: block;
    color: #000;
  }

  .error-text {
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.red};
    margin-top: 4px;
    &:empty {
      display: none;
    }
  }
`;

const StyledDropdownOptions = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: 1000;
  width: 100%;
  max-height: 252px;
  min-height: 32px;
  overflow: auto;
  padding: 8px 0;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};
  background: white;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .no-results {
    padding: 0 12px;
    margin-top: 10px;
    text-align: center;
  }
`;

const StyledDropdownOption = styled.li`
  font-size: 12px;
  line-height: 18px;
  padding: 8px 12px 8px 36px;
  min-height: 36px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    font-size: 10px;
    color: ${props => props.theme.colors.grayDark};
    pointer-events: none;
  }

  .icon-checkmark {
    position: absolute;
    fill: ${props => props.theme.colors.green};
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: ${props => props.theme.colors.grayDark};
  }
`;

const StyledDropdownOptionCheckbox = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  min-height: 36px;
  overflow: hidden;

  .checkbox {
    width: 100%;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const StyledOptionTag = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 4px;
  border: 1px solid black;
  border-radius: ${props => props.theme.misc.borderRadius};
  margin: 4px 4px 0 0;

  .option-remove-btn {
    border: none;
    background: none;
    padding: 0;
    margin-left: 4px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    cursor: pointer;

    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
`;

const StyledDropdownButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 8px;
  border: 1px solid black;
  background: white;
  border-radius: ${props => props.theme.misc.borderRadius};
  transition: ${props => props.theme.transitions.standart};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.red};
  }

  label {
    font-weight: 500;
    color: ${props => props.theme.colors.grayDark};
    margin-right: 4px;
  }

  .dropdown-text {
    margin-right: auto;
  }

  .icon {
    vertical-align: middle;
    margin-left: 4px;
  }
`;
