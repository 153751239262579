import { useEffect, useCallback } from 'react';

const defaultConfig = { condition: true };

const useOutsideClick = (target, callback, { condition } = defaultConfig) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (target && target.contains && !target.contains(e.target)) callback(e);
    },
    [callback, target]
  );

  useEffect(() => {
    if (!condition) return;
    // for some reason 'mousedown' stopped working
    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [condition, handleClick]);
};

export default useOutsideClick;
