import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * Disable button.
   */
  disabled?: boolean;
  /**
   * Make button light-pink.
   */
  secondary?: boolean;
  /**
   * Make secondary button black.
   */
  secondaryBlack?: boolean;
  /**
   * Render without border and background.
   */
  link?: boolean;
  /**
   * Render without border and background.
   */
  simple?: boolean;
  /**
   * Makes button size fixed - 300px wide and 40px tall
   */
  large?: boolean;
  /**
   * Render button as another component or tag
   */
  as?: string | React.ComponentType<any>;
  /**
   * Create path when button rendered as link tag
   */
  to?: string;
  /**
   * React ref
   */
  ref?: React.RefObject<any>;
}

const Button = React.forwardRef<any, ButtonProps>(
  (
    {
      as: tag,
      disabled,
      link,
      secondary,
      secondaryBlack,
      simple,
      large,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const Component: any = link ? StyledLinkButton : StyledButton;
    return (
      <Component
        as={tag}
        disabled={disabled}
        ref={ref}
        className={clsx(
          'button',
          { disabled, secondary, simple, large, secondaryBlack, link },
          className
        )}
        {...props}>
        {children}
      </Component>
    );
  }
);

export default React.memo(Button);

const StyledButton = styled.button`
  background: ${props => props.theme.colors.primary};
  box-sizing: border-box;
  border: 2px solid transparent;
  color: ${props => props.theme.colors.white};
  fill: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  padding: 0 8px;
  border-radius: ${props => props.theme.misc.borderRadius};
  transition: border 200ms, background 200ms, color 200ms, fill 200ms;
  cursor: pointer;

  .icon {
    vertical-align: middle;
  }

  &:hover,
  &:active {
    background: white;
    border: 2px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    fill: ${props => props.theme.colors.primary};
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    &:hover {
      background: ${props => props.theme.colors.primary};
      border: 2px solid transparent;
      color: ${props => props.theme.colors.white};
      fill: ${props => props.theme.colors.white};
    }
  }
  &.secondary {
    background: ${props => props.theme.colors.pink};
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    fill: ${props => props.theme.colors.primary};
    &:hover,
    &:active {
      background: white;
    }
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      &:hover {
        background: ${props => props.theme.colors.pink};
      }
    }
  }
  &.secondaryBlack {
    background: ${props => props.theme.colors.white};
    font-weight: 500;
    color: ${props => props.theme.colors.black};
    fill: ${props => props.theme.colors.black};
    border: 2px solid ${props => props.theme.colors.black};
    &:hover,
    &:active {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
      border: 2px solid ${props => props.theme.colors.primary};
    }
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      &:hover {
        background: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.black};
        fill: ${props => props.theme.colors.black};
        border: 2px solid ${props => props.theme.colors.black};
      }
    }
  }
  &.secondaryBlack,
  &.secondary {
    &.disabled {
      background: ${props => props.theme.colors.white};
      pointer-events: none;
      color: ${props => props.theme.colors.grayDark};
      border: 1px solid ${props => props.theme.colors.grayDark};
    }
  }
  &.simple {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: initial;
    fill: initial;
    height: auto;
    width: auto;
    padding: 0;
    background: none;
    border: none;
    &:focus {
      opacity: 0.5;
    }
  }
  &.large {
    width: 300px;
    height: 40px;
  }
  &.disabled {
    pointer-events: none;
    fill: ${props => props.theme.colors.grayDark};
    color: ${props => props.theme.colors.grayDark};
    background: ${props => props.theme.colors.graphite};
    .icon {
      fill: ${props => props.theme.colors.grayDark};
    }
  }
  &:focus {
    outline: 0;
  }
`;

const StyledLinkButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.primary};
  fill: ${props => props.theme.colors.primary};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  padding: 0;
  &.disabled {
    pointer-events: none;
    color: #8b8b8b;
    background: #cdcdcd;
  }
`;
